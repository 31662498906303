<template>
  <Page :loading="loading" class="customer-content">
    <!-- 导航栏 -->
    <NavBar title="客服中心"> </NavBar>

    <ul class="content pa-16">
      <li
        @click="handleRouterPush(index)"
        class="d-flex justify-space-between"
        v-for="(item, index) in controls"
        :key="index"
      >
        <div class="d-flex justify-space-between">
          <div class="icon-circle">
            <Icon svgClass="icon-font" :name="item.icon"></Icon>
          </div>
          <div class="ml-12 d-flex flex-column justify-space-between">
            <strong class="font-16">{{ item.name }}</strong>
            <div class="text_muted">{{ item.detail }}</div>
          </div>
        </div>
        <div class="pt-10">
          <img src="@/assets/icon_back.png" />
        </div>
      </li>
      <li class="phone-wrap ">
        <div class="d-flex ">
          <div class="icon-circle">
            <Icon svgClass="icon-font" name="dianhua"></Icon>
          </div>
          <div class="ml-12 d-flex flex-column justify-space-between">
            <strong class="font-16">客服电话</strong>
            <div class="text_muted">如有疑问请电话咨询平台服务</div>
          </div>
        </div>
        <a v-if="phone" class="phone-link" @click="handleNoPhoneClick"> </a>
        <div v-else class="phone-link" @click="handleNoPhoneClick"></div>
      </li>
    </ul>
  </Page>
</template>

<script>
import Icon from '@/components/Icon/index.vue';
import NavBar from '@/components/NavBar/index.vue';
import { defineComponent, ref, onBeforeMount } from '@vue/composition-api';
import { useRouter } from '@/utils/compsitionHack';
import { getConsultationList, getComplainList, getServicePhone } from '@api/customer';
import { Toast } from 'vant';
import { PageEnum } from '@/enums/pageEnum';

export default defineComponent({
  name: 'customer',
  components: {
    Icon,
    NavBar
  },
  setup() {
    const phone = ref('');
    const loading = ref(true);
    const controls = [
      {
        name: '业务咨询',
        icon: 'ywzx',
        detail: '找货咨询、货源详情、线路报价'
      },
      {
        name: '举报投诉',
        icon: 'jets',
        detail: '货主违规行为、运费克扣'
      }
    ];
    const router = useRouter();

    onBeforeMount(() => {
      getServicePhone().then(res => {
        phone.value = res.data;
        loading.value = false;
      });
    });

    const handleRouterPush = async type => {
      let api;
      if (!type) api = getConsultationList;
      else api = getComplainList;
      try {
        const res = await api();
        if (res && res.data.length) router.push({ path: PageEnum.CUSTOMER_CONSULTING, query: { type: type } });
        else router.push({ path: PageEnum.CUSTOMER_EDIT, query: { type: type } });
      } catch (err) {
        Toast.fail(err);
      }
    };

    const handleNoPhoneClick = () => {
      if (phone.value) {
        window.location.href = 'tel:' + phone.value;
      } else {
        Toast.fail('平台未提供客服电话');
      }
    };

    return { handleRouterPush, controls, phone, handleNoPhoneClick, loading };
  }
});
</script>
<style lang="less">
.customer-content {
  background: #f1f3f5;
  height: 100vh;
  .content {
    li {
      margin-bottom: 0.16rem;
      border-radius: 4px;
      background: white;
      padding: 0.24rem 0.16rem;
      .icon-circle {
        width: 0.42rem;
        background: rgba(64, 152, 255, 0.1);
        border-radius: 50%;
        height: 0.42rem;
        line-height: 0.42rem;
        text-align: center;
      }
    }
  }

  .phone-wrap {
    position: relative;
  }
  .phone-link {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
}
</style>
